import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import VerticalMenu from "../../components/VerticalMenu";
import HorizontalMenu from "../../components/HorizontalMenu";
import Container from "@mui/material/Container";
import Helmet from "react-helmet";
import favicon from "../../images/favicon.ico";
import PageTitle from "../../components/PageComponents/PageTitle";
import Paragraph from "../../components/PageComponents/Paragraph";
import ParagraphTitle from "../../components/PageComponents/ParagraphTitle";
import SupportButton from "../../components/SupportButton";
import CodeContainer from "../../components/CodeContainer";
import ParagraphList from "../../components/PageComponents/ParagraphList";
import { pageStyles, pageContent } from "../../styleConstants";

const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <HorizontalMenu />
      <Box style={pageContent}>
        <VerticalMenu page={2} />

        <Container maxWidth="md" style={{ wordWrap: "break-word" }}>
          <PageTitle text="Testing environment" />
          <Paragraph
            text="If you wish to test your API before you go to production, GLOBHE offers a testing environment in which you can place 
          dummy orders and receive dummy data. Note that the API key for the testing environment is not the same as
          your key on production. You need to create a new profile on stagingpage.globhe.com and copy that API key from there."
          />
          <ParagraphTitle text="How to work in the testing environment" />
          <ol>
            <ParagraphList text="Go to stagingpage.globhe.com" />
            <ParagraphList text="Create a client account there" />
            <ParagraphList text="Contact support to get your testing API key granted and to receive dummy data when orders are placed" />
          </ol>
          <br />
          <ParagraphTitle
            titleSmall
            text="baseUrl for the testing environment"
          />
          <Paragraph text="Use this baseUrl for testing only. It is different from the baseUrl of production. Note that the baseUrl differs according to the GLOBHE API version that you are using." />
          <Grid container sx={{ alignItems: "center" }}>
            <Grid item xs={2}>
              <Typography>Version 1:</Typography>
            </Grid>
            <Grid item xs={10}>
              <CodeContainer
                red
                text="https://us-central1-globhe-d3087.cloudfunctions.net/api/v1"
              />
            </Grid>
            <Grid item xs={2}>
              <Typography>Version 2:</Typography>
            </Grid>
            <Grid item xs={10}>
              <CodeContainer
                red
                text="https://us-central1-globhe-d3087.cloudfunctions.net/api/v2"
              />
            </Grid>
          </Grid>
          <br />
          <ParagraphTitle text="What to do next" />
          <Paragraph text="Once you are satisfied and your testing environment is working well, then you can replace the testing credentials with your production ones:" />
          <ol>
            <ParagraphList text="Replace your testing baseUrl with the production baseUrl" />
            <ParagraphList text="Replace your testing API key with your production API key" />
            <ParagraphList text="Place orders in production in the same way you did in the testing environment" />
          </ol>
          <br />
          <ParagraphTitle text="Activate your API key" />
          <Paragraph text="To activate your API key on either the production or the testing environment, please contact support and you will receive a reply in less than 24 hours." />
          <SupportButton />
        </Container>
      </Box>
      <Helmet>
        <meta charSet="utf-8" />
        <title>GLOBHE&apos;s developers docs</title>
        <link rel="icon" href={favicon} />
      </Helmet>
    </main>
  );
};

export default IndexPage;
