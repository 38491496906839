import { Typography } from "@mui/material";
import * as React from "react";
import PropTypes from "prop-types";

const ParagraphList = ({ text }) => {
  return (
    <li>
      <Typography variant="body1">{text}</Typography>
    </li>
  );
};

ParagraphList.propTypes = {
  text: PropTypes.string.isRequired,
};
export default ParagraphList;
